<template>
	<div class="page_privacy">
		<section class="header-bg" style="">
		</section>
		<section class="container-1200" style="margin-top: 40px;">

			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Privacy policy</strong>
				</p>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">

			<div class="card_box">
				<p style="margin-top: 16px;">This notice applies to the websites at contrimetric.com and related
					services. We respect your concerns about privacy and value our relationship with you.
					This notice explains what personal data we collect, how we may use and manage it and the rights you
					may have in relation to such personal data. When we refer to “personal data” in this notice,
					we mean information relating to an identified or identifiable individual that we collect and use in
					connection with contrimetric.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">How do we collect and use personal data?</div>
			</div>

			<div class="card_box">
				<ul>
					<li><span style="font-weight: bold;">Information you provide to us directly online.</span> For
						example, when you register to use an contrimetric product, complete one of our web forms,
						subscribe to updates or make a support request, we collect the personal data you provide, like
						your name, email address and other basic contact details / information. We will use this
						information to enable you to access and use contrimetric or fulfil the request you’ ve made.
					</li>
					<li><span style="font-weight: bold;">Information we collect from your use of contrimetric.</span>
						When you use contrimetric, we may collect information about that usage and other technical
						information, such as your IP address, browser type and any referring website addresses. For
						example, we collect IP addresses from all visitors to our contrimetric Details Page in order to
						determine whether or not it should be fully “unlocked” for the visitor (e.g. because they’re
						part of an organisational site license). We may also combine this automatically collected log
						information with other information we collect about you and use it to keep a record of our
						interaction and to enable us to support, personalise and improve contrimetric. We may also
						collect this type of information using cookies and other similar technologies.</li>
					<li><span style="font-weight: bold;">Information we collect from our other interactions / business
							dealings.</span> For example, if you attend a webinar, contact us via social media or
						otherwise interact with our business, including as a representative of a current / prospective
						customer, supplier, or partner, we may track and make a record of those interactions, which may
						contain personal data. </li>
					<li><span style="font-weight: bold;">Information we collect from third parties or publicly available
							sources.</span> contrimetric tracks and analyse the online attention paid to scholarly
						research outputs. This is done by collecting metadata about the outputs we track, as well as by
						collecting mentions of research outputs from a variety of online attention sources, which may
						contain personal data. For example, we collect publicly available information about authors of
						research outputs tracked by contrimetric (e.g., journals, journal articles, datasets, and other
						content), and about creators or authors of mentions, for research outputs tracked by
						contrimetric (e.g., social media, news, policy, blog, video and patent mentions). How we use the
						personal data we collect varies depending on the contrimetric product.</li>
				</ul>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">In all the above cases, where we have a relationship with you, we may also
					use the personal data we collect to manage and keep a record of that relationship and other business
					administration purposes you’d reasonably expect and, subject always to your preferences, to provide
					information we think may be of interest to you.
					In many of the above cases, we may also use the information collected to create aggregate or other
					non-personal data to enable us to benchmark and improve contrimetric and for other analytical /
					research purposes.
					Unless we specifically request it, you should not provide us with any sensitive personal
					information. If you provide personal data to us about someone else (such as one of your colleagues),
					please ensure that you have their permission to do so and that they’re aware of the contents of this
					notice.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">The legal basis for contrimetric’s use of your personal data</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">In order to comply with Malaysia data privacy laws, we are required to set
					out the legal bases for our use of your personal data, which are as follows:</p>
			</div>

			<div class="card_box">
				<ul>
					<li>Where you have given us your explicit consent, which you can withdraw at any time. For example,
						we rely on your consent to fulfil specific requests you’ve made, such as to receive our emails,
						or provide information you’ve opted-in to receive;</li>
					<li>Where the processing is necessary for the performance of our contract with you, or to enter into
						such a contract. For example, if you register to use contrimetric, we will need to use your
						details to set-up and administer your account;</li>
					<li>Where the processing is necessary to comply with our legal obligations; or</li>
					<li>The processing is in our legitimate interests, provided these are not overridden by your
						individual rights. For example, we rely on our legitimate interests: to use the publicly
						available personal data we collect from scholarly research outputs and on-line attention sources
						within contrimetric, including to develop metrics and qualitative data we use to analyse and
						showcase the attention and influence of research, amongst other things, which we believe adds
						value to the research community and provides a valuable complement to more traditional measures.
						We also rely on our legitimate interests to contact you when you’ve not previously given us your
						consent to do so such as to tell you about things that we think might be of interest to you, or
						to ask for your feedback or opinion on us or our products / services; to collect publicly
						available information, such as posts on social media, for market research and product
						improvement purposes.</li>
				</ul>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Who we share your personal data with?</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">We may share your personal data within the contrimetric group on a
					confidential basis for our internal administrative, billing and other business purposes, and to
					fulfil a request that you might have made. We do not generally disclose or share personal data with
					third parties, except where it’s necessary for legitimate business reasons, such as:</p>
			</div>

			<div class="card_box">
				<ul>
					<li>In such circumstances for which you have given your consent;</li>
					<li>To the agents, advisers and service providers that assist us in running / we use to administer
						our business;</li>
					<li>To the subcontractors and service providers we use to provide and support contrimetric, for
						example, providers of hosting, security and infrastructure and customer support platforms;</li>
					<li>If contrimetric is accessed via, or otherwise integrated with, other contrimetric products, or
						if required to enable the integration or use of third party resources accessed / used on
						contrimetric;</li>
					<li>If part of our business is sold to or integrated with another business, to our advisers and any
						prospective purchasers (and their advisers);</li>
					<li>In the case of usage information relating to your access of a version of contrimetric that is
						paid for by, or co-branded or branded with the name of, another organisation, to that
						organisation;</li>
					<li>If necessary for the performance of the contract we have with you or in order to enforce any
						claims we are entitled to;</li>
					<li>If required by law or ordered by a court.</li>
				</ul>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">Note, where you are using contrimetric pursuant to an organisation’s
					subscription, that organisation may have certain admin rights over your account, including the
					ability to track your usage; and we will act in accordance with any instructions they may give in
					respect of such information (if any) they may provide to us about you or which we may collect on
					their behalf (whose policies - not this policy - will govern how the personal data we process on
					their behalf is used).</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Security and storage</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">We take appropriate technical and organisational security measures to
					protect personal data from accidental or unlawful destruction, accidental loss and unauthorised
					access, destruction, misuse, modification or disclosure, including generally accepted standards
					designed to protect personal data provided to us, both during transmission and once it is received. 
					We only keep your personal data for as long as it is necessary for the purposes for which it was
					collected, after which it will be destroyed, erased or anonymised. In the case of research outputs
					or mentions, we may need to retain a record of your association with that content indefinitely so we
					can continue to corroborate our metrics.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Marketing</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">Depending on your preferences, we may contact you about things we think
					might be of interest to you. Some of these messages may be tailored to you, based on your interests
					(e.g. previous browsing activity), public profile and posts (e.g. on social media), use of our
					products / services, public research history and other information we may hold. If you no longer
					wish to receive such communications, you can click the unsubscribe button. Note, even if you
					unsubscribe from marketing messages, we may still send you emails about the services you use, such
					as details of new functionality / changes to legal terms of use.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Cookies</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">Please see our cookie settings for information on how we collect personal
					data using cookies and similar technologies on contrimetric.com domains. In some cases, contrimetric
					services may be provided via other domains, in which case, please refer to the cookie policy
					displayed on the corresponding site.</p>
			</div>
			<div class="titleSecond_box">
				<div class="titleSecond">Your rights</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">Various data privacy laws give rights to individuals in respect of personal
					data that organisations hold about them. For example, under Malaysia data privacy laws, you may be
					entitled:</p>
			</div>

			<div class="card_box">
				<ul>
					<li>To request a copy of the personal data that we hold about you;</li>
					<li>To object to the processing of your personal data; or</li>
					<li>To request that your personal data is rectified or deleted, or its processing limited.</li>
				</ul>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">To make any requests regarding your personal data, please email us at <a
						style="color: #0000ff;"
						href="mailto:contact@contrimetric.com?subject=&amp;body=">contact@contrimetric.com</a>. We will
					comply with any such requests as required in accordance with applicable law. Please be aware,
					however, that there are typically a number of limitations to these rights, and there may be
					circumstances where we’re not able to comply with your request.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Contact details</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">If you would like any further information, or have any questions or
					concerns, regarding your personal data, please email us at <a style="color: #0000ff;"
						href="mailto:contact@contrimetric.com?subject=&amp;body=">contact@contrimetric.com</a>.</p>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">You have the right to make a complaint at any time to your local
					supervisory authority for data protection issues. We would, however, appreciate the chance to deal
					with your concerns in the first instance.</p>
			</div>

			<div class="titleSecond_box">
				<div class="titleSecond">Changes to this notice</div>
			</div>

			<div class="card_box">
				<p style="margin-top: 16px;">We reserve the right to modify or replace this notice at any time by
					posting the revised notice on our website. You are responsible for reviewing any such change each
					time you access any part of contrimetric.</p>
			</div>

			<div class="titleSecond_box">
				<span style="font-weight: bold;">Last Updated: </span>30th September 2024
			</div>
		</section>
	</div>

</template>
<script>
export default {
	name: "policy",
	data() {
		return {};
	},
	watch: {},
	created() {

	},
	mounted() { },
	methods: {

	},
}
</script>

<style lang="scss" scoped>
.page_privacy {
	ul {
		li {
			position: relative;
			padding-left: 20px;

			&::before {
				content: "•";
				font-size: 24px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}

.titleFirst {
	font-size: 22px;
	font-weight: bold;
}

.titleSecond_box {
	margin: 20px 0px 0px 0;
	text-align: justify;
	line-height: 22px;

	.titleSecond {
		font-size: 20px;
		font-weight: bold;
	}
}

.card_box {
	margin: 20px 0px 0px 0;
	text-align: justify;
	line-height: 22px;
}
</style>